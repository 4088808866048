import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/app/benefit": [6,[2]],
		"/app/benefit/aliexpress": [~7,[2]],
		"/app/benefit/attendance": [~8,[2]],
		"/app/benefit/buzzvil/action": [9,[2]],
		"/app/benefit/coupang": [~10,[2]],
		"/app/benefit/cpa-campaigns": [11,[2]],
		"/app/benefit/cpa-campaigns/[campaignId]": [~12,[2]],
		"/app/benefit/fortune": [13,[2]],
		"/app/benefit/friends": [~14,[2]],
		"/app/benefit/friends/received": [~15,[2]],
		"/app/benefit/friends/sent": [~16,[2]],
		"/app/benefit/friends/welcome": [17,[2]],
		"/app/benefit/gamecenter": [~18,[2]],
		"/app/benefit/gamecenter/3": [~19,[2]],
		"/app/benefit/gamecenter/4": [~20,[2]],
		"/app/benefit/gamecenter/5": [~21,[2]],
		"/app/benefit/gamecenter/total": [~23,[2]],
		"/app/benefit/gamecenter/total/[id]": [~24,[2]],
		"/app/benefit/gamecenter/[id]": [~22,[2]],
		"/app/benefit/quiz": [~25,[2]],
		"/app/benefit/quiz/brand/[id]": [~26,[2]],
		"/app/benefit/quiz/today": [~27,[2]],
		"/app/benefit/rewarded_video": [~28,[2]],
		"/app/benefit/slot-machine": [~32,[2]],
		"/app/benefit/slot": [~29,[2]],
		"/app/benefit/slot/exchange": [~30,[2]],
		"/app/benefit/slot/howto": [31,[2]],
		"/app/benefit/surprise/[id]": [33,[2]],
		"/app/benefit/week": [~34,[2]],
		"/app/benefit/week/coupang": [~35,[2]],
		"/app/chip/history": [~36,[2]],
		"/app/coin/history": [~37,[2]],
		"/app/community/[board_id]/articles": [~38,[2]],
		"/app/event/step": [~39,[2]],
		"/app/exchange": [~40,[2,3]],
		"/app/exchange/coin/[id]": [~42,[2,3]],
		"/app/exchange/coin/[id]/request": [~43,[2,3]],
		"/app/exchange/stock/[market]/buy": [~44,[2,3,4]],
		"/app/exchange/stock/[market]/info": [~45,[2,3,4]],
		"/app/exchange/stock/[market]/sell": [~46,[2,3,4]],
		"/app/exchange/stock/[market]/wallet": [~47,[2,3,4]],
		"/app/exchange/[id]": [~41,[2,3]],
		"/app/home": [~48,[2]],
		"/app/loading": [49,[2]],
		"/app/mission": [50,[2]],
		"/app/mission/rv-plus": [~51,[2]],
		"/app/mission/step": [~52,[2]],
		"/app/mission/step/guide": [53,[2]],
		"/app/play/reels": [54,[2]],
		"/app/play/toons": [~55,[2]],
		"/app/setting": [~56,[2]],
		"/app/setting/android/health-connect": [57,[2]],
		"/app/setting/app-optimize": [58,[2]],
		"/app/setting/ios/health-guide": [59,[2]],
		"/app/setting/ipo": [~60,[2]],
		"/app/setting/my": [61,[2]],
		"/app/setting/my/certification": [~62,[2]],
		"/app/setting/my/noti-policy": [~63,[2]],
		"/app/setting/privacy": [64,[2]],
		"/app/setting/promotion/code": [65,[2]],
		"/app/setting/referral": [~66,[2]],
		"/app/setting/terms": [67,[2]],
		"/auth/login": [68],
		"/auth/logout": [69],
		"/auth/signup": [70],
		"/auth/signup/completed": [~71],
		"/o/att": [72],
		"/o/playground": [~73],
		"/o/promotion/231101-challenge": [74],
		"/o/promotion/2312-newbie": [75],
		"/o/promotion/2401-review": [95],
		"/o/promotion/240222-coupang": [96],
		"/o/promotion/2403-quiz": [97],
		"/o/promotion/2403-review": [98],
		"/o/promotion/2404-catgame": [99],
		"/o/promotion/2404-payback": [~100],
		"/o/promotion/2405-coupang": [~101],
		"/o/promotion/24/05-pizza": [~76],
		"/o/promotion/24/07-friends": [~77],
		"/o/promotion/24/08-dndn-ipo": [78],
		"/o/promotion/24/09-chuseok": [~79],
		"/o/promotion/24/09-early-slot": [~80],
		"/o/promotion/24/09-kbank/1": [~81],
		"/o/promotion/24/09-kbank/2": [~82],
		"/o/promotion/24/09-kbank/3": [~83],
		"/o/promotion/24/09-kbank/[id]": [~84],
		"/o/promotion/24/10-cheongo-bunny": [~85],
		"/o/promotion/24/10-homeplus": [~86],
		"/o/promotion/24/10-kbo": [~87],
		"/o/promotion/24/10-prizm": [~88],
		"/o/promotion/24/11-axa": [89],
		"/o/promotion/24/11-birthday": [~90],
		"/o/promotion/24/11-homeplus": [~91],
		"/o/promotion/24/11-monimo": [~92],
		"/o/promotion/24/11-phoenix": [~93],
		"/o/promotion/24/11-temu": [~94],
		"/o/test": [102],
		"/o/test/event": [~103]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';