import { goto } from '$app/navigation';
import { publicConfig } from '$lib/config';
import * as Sentry from '@sentry/sveltekit';
import { closeAllModals } from './lib/stores/modal-store';

Sentry.init({
  dsn: 'https://2d929037717b4b292ecca11db428b825@o157094.ingest.sentry.io/4506341271994368',
  sampleRate: 0.2,
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: publicConfig.PUBLIC_ENV,
  integrations: [new Sentry.Replay()],
});

export const handleError = Sentry.handleErrorWithSentry();

// 비트버니 앱에서 CSR로 웹뷰 이동을 할 수 있도록 도와주는 함수
let urlGoingTo: string | null = null;
window._gotoInCsrV2 = async (url, state) => {
  urlGoingTo = url;
  await goto('/app/loading', state);
  if (urlGoingTo !== url) {
    return;
  }
  try {
    if (!url.endsWith('auth/signup')) {
      window.IMP.close();
    }
    closeAllModals();
    await goto(url, { replaceState: true });
  } catch (e) {
    console.error(e);
    window.location.href = url;
  }
  urlGoingTo = null;
};
